import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15003282"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facade-card-wr" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "title" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.props.facade?.name), 1),
      _createElementVNode("button", {
        class: "button default edit edit-icon",
        onClick: $setup.updateFacade
      }),
      _createElementVNode("button", {
        class: "button button-with-icon trash-icon",
        onClick: $setup.deleteFacade
      }, [
        _createElementVNode("img", {
          class: "trash-icon-img",
          src: require(`@/assets/icons/trash.svg`)
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString($setup.formatPriceTrim($setup.props.facade?.price)), 1),
    _createElementVNode("img", {
      class: "facade-img",
      src: $setup.props.facade?.url
    }, null, 8, _hoisted_6)
  ]))
}